import { Fragment } from 'react';
import {
    Datagrid, TextField, ReferenceField,
    TopToolbar, RefreshButton, List, useTranslate,
    BulkDeleteWithConfirmButton,
    useRecordContext,
    Pagination,
    FunctionField,
    usePermissions,
} from 'react-admin';
import {
    Button,
    Tooltip,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import VmCreate from '../views/workspaces/vmCreate';
import {
    ControlVm, CreateInstanceTemplate, OpenGuacConsole,
    OpenVmConsole, RebootVm, StartAllVms, StopAllVms
} from '../views/vms/vmControl';
import { makeStyles } from '@mui/styles';
import DeployInstanceTemplate from '../views/pods/instanceTemplateDeploy';
import { Visibility } from '@material-ui/icons';
import ShowRouterInterfaces from '../views/routers/routerInterfaceList';
import { ShowIpAddress } from '../components/ipAddressField';
import StatusField from './customStatusField';

const useStyles = makeStyles((theme) => ({
    tableLastCell: {
        width: '100%',
        textAlign: 'right'
    },
    descriptionCell: {
        width: '20%'
    },
    nameCell: {
        whiteSpace: 'nowrap',
    },
    button: {
        minWidth: '10px',
        width: '36px',
        height: '32px'
    },
    nameLinkCell: {
        width: '150px',  // Adjust width for name column
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        font: 'inherit',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));
//const classes = useStyles();

// Standardized column widths
const columnWidths = {
    id: '100px',
    width: '400px',
};

const CustomEmpty = () => <div>No data found</div>;


const CustomPagination = (props) => (
    <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);


const CustomList = (props) => {
    const { source, id, filters } = props;

    // Determine the correct resource and filters
    let customSource = source;
    let customFilters = { ...filters, pod: id }; // Always include pod filter

    if (source === 'firewalls') {
        customSource = 'vms'; // Ensure correct resource for firewalls
        customFilters = { ...filters, pod: id, type: "firewall" }; // Explicitly include firewalls
    } else if (source === 'vms') {
        customSource = 'vms'; // Ensure correct resource for firewalls
        customFilters = { ...filters, pod: id, type: { $ne: "firewall" } }; // Explicitly exclude firewalls
    }

    return (
        <div>
            <List
                {...props}
                resource={customSource} // Dynamically set the resource (networks, routers, or vms)
                filter={customFilters} // Apply correct filters
                pagination={source === "vms" ? <CustomPagination props={props} /> : null} // Pagination only for VmsTable
                perPage={source === "vms" ? 10 : undefined} // Rows per page only for VmsTable
                sort={{ field: 'id', order: 'ASC' }}
                actions={source === "vms" ? <Actions data={props} /> : null} // Actions only for VmsTable
                empty={false}
                disableSyncWithLocation
            >
                {source === "routers"
                    ? <RouterTable />
                    : source === "networks"
                        ? <NetworkTable />
                        : <VmsTable />}
            </List>
        </div>
    );
};

const BulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton resource='vms' />
    </Fragment>
);


const NetworkTable = () => {
    const translate = useTranslate();
    return (
        <Datagrid sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />}
            sx={{ '& .MuiTableCell-root': { width: columnWidths.width } }}
            bulkActionButtons={false} >
            <TextField source="name" sx={{ width: columnWidths.name }} label={translate('app.networks.name')} />
            <TextField source="description" sx={{ width: columnWidths.description }} label={translate('app.networks.description')} />
            <TextField source="cidr" label={translate('app.networks.cidr')} />
            <TextField source="osNetworkStatus" label={translate('app.networks.status')} />
            <TextField label='' />
        </Datagrid>
    );
};

const RouterTable = () => {
    const translate = useTranslate();
    return (
        <Datagrid sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />}
            sx={{ '& .MuiTableCell-root': { width: columnWidths.width } }}
            bulkActionButtons={false} >
            <TextField source="name" label={translate('app.routers.name')} />
            <TextField source="description" label={translate('app.routers.description')} />
            <ReferenceField source="pod" reference="pods" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="osRouterStatus" label={translate('app.routers.status')} />
            <ShowRouterInterfaces label="Interfaces" />
        </Datagrid>
    );
};


const VmsTable = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Datagrid sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />}
            bulkActionButtons={<BulkActionButtons />}>
            <FunctionField
                label={translate(`app.vms.name`)}
                sortBy="name" 
                render={record => (
                    <Link to={`/vms/${record.id}/show`} className={classes.nameLinkCell}>
                        {record.name}
                    </Link>
                )}
            />
            <TextField source="description" cellClassName={classes.descriptionCell} label={translate('app.vms.description')} />
            <ReferenceField source='flavor' reference='flavors' link='show' cellClassName={classes.nameCell}>
                <TextField source='name' />
            </ReferenceField>
            <TextField source="guest_OS" label={translate('app.vms.guestOS')} />
            <ReferenceField source='pod' reference='pods' link='show' cellClassName={classes.nameCell}>
                <TextField source='name' />
            </ReferenceField>
            <ShowIpAddress label={translate('app.pods.ipAddress')} />
            <StatusField source="osServerStatus" label={translate('app.vms.status')} />
            <ListActionButtons cellClassName={classes.tableLastCell} />
        </Datagrid>
    );
};

const FirewallTable = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Datagrid sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />}
            bulkActionButtons={<BulkActionButtons />}>
            <TextField source="name" label={translate('app.vms.name')} />
            <TextField source="description" cellClassName={classes.descriptionCell} label={translate('app.vms.description')} />
            <ReferenceField source='flavor' reference='flavors' link='show' cellClassName={classes.nameCell}>
                <TextField source='name' />
            </ReferenceField>
            <TextField source="guest_OS" label={translate('app.vms.guestOS')} />
            <ReferenceField source='pod' reference='pods' link='show' cellClassName={classes.nameCell}>
                <TextField source='name' />
            </ReferenceField>
            <ShowIpAddress label={translate('app.pods.ipAddress')} />
            <StatusField source="osServerStatus" label={translate('app.vms.status')} />
            <ListActionButtons cellClassName={classes.tableLastCell} />
        </Datagrid>
    );
};

const Actions = (props) => {
    return (
        <TopToolbar>
            <VmCreate pod={props.data} />
            <DeployInstanceTemplate pod={props.data} />
            <StartAllVms pod={props.data} />
            <StopAllVms pod={props.data} />
            <RefreshButton />
        </TopToolbar>
    )
};


const ListActionButtons = (props) => {
    const translate = useTranslate();
    const vm = useRecordContext();
    const location = useLocation();
    const classes = useStyles();
    const { permissions, isLoading } = usePermissions();

    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;
    
    // Check if vm (record context) is defined before rendering buttons that rely on it
    if (!vm) {
        return null; // or return an empty Fragment if vm is not available
    }
    
    const shouldBeDisabled = (permissions.length === 1 && permissions.includes('trainee')) && vm.type === 'firewall';

    return (
        <Fragment>
            {!((permissions.length === 1 && permissions.includes('trainee')) && vm.type === 'firewall') ? '' : '' }

            <span onClick={(event) => event.stopPropagation()}>
                <Tooltip title="Show" arrow placement="top">
                    <span style={{ width: '100%' }}>
                        <Button className={classes.button} size='large'
                            component={Link}
                            to={`${location.pathname}/${vm.id}`}
                            startIcon={<Visibility />}
                            disabled={shouldBeDisabled}
                        >
                        </Button>
                    </span>
                </Tooltip>
                <ControlVm action={translate('app.vms.action.start')}  shouldBeDisabled={shouldBeDisabled} />
                <ControlVm action={translate('app.vms.action.stop')} shouldBeDisabled={shouldBeDisabled}  />
                <ControlVm action={translate('app.vms.action.suspend')} shouldBeDisabled={shouldBeDisabled}  />
                <ControlVm action={translate('app.vms.action.resume')} shouldBeDisabled={shouldBeDisabled}  />
                <RebootVm shouldBeDisabled={shouldBeDisabled}  />
                <OpenVmConsole shouldBeDisabled={shouldBeDisabled}  />
                <OpenGuacConsole shouldBeDisabled={shouldBeDisabled} />
                <CreateInstanceTemplate shouldBeDisabled={shouldBeDisabled} />
            </span>
        </Fragment>
    );
};


export default CustomList;