import { Loading, usePermissions } from "react-admin";


const HasPermissions = (requiredRole) => {
    const { permissions, isLoading } = usePermissions();

    if (isLoading) return <Loading />;

    if (!permissions) return null;

    return permissions.includes(requiredRole);
};

export default HasPermissions;