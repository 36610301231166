const englishCustomMessages = {
  resources: {
    users: {
      name: 'Users',
      fields: {
        email: 'Username',
      }
    },
    admin: {
      name: 'Admin'
    },
    Design: {
      name: 'Design'
    },
    myworkspace: {
      name: 'My Workspace'
    },
    mypod: {
      name: 'My Pod'
    },
    images: {
      name: 'Sources'
    },
    classrooms: {
      name: 'Classrooms'
    },
    pods: {
      name: 'Pods'
    },
    flavors: {
      name: 'Flavors'
    },
    workspaces: {
      name: 'Workspaces'
    },
    trainingTemplates: {
      name: 'Training Templates'
    },
    instanceTemplates: {
      name: 'Instance Templates'
    },
    networks: {
      name: 'Networks'
    },
    routers: {
      name: 'Routers'
    },
    vms: {
      name: 'Instances'
    },
    firewalls: {
      name: 'Firewalls'
    },
    trainings: {
      name: 'Trainings'
    },
    volumes: {
      name: 'Volumes'
    },
    snapshots: {
      name: 'Snapshots'
    },
    myclassroom: {
      name: 'My classroom'
    },
    securityGroups: {
      name: 'Security Groups'
    },
    files: {
      name: 'Files'
    },
    tasks: {
      name: 'Task'
    },
    hypervisors: {
      name: 'Hypervisors'
    }
  },
  app: {
    resetPassword: {
      title: 'Reset Password',
      resetButton: 'Reset Password',
      redirectToLoginButton: 'Go to the login page',
      success: 'Password has been changed successfully.',
      success2: 'You will receive shortly an email with the link to reset your password.',
      error1: 'Oops ! Something went wrong ...',
      error2: 'Please try again later',
      confirmPassword: 'Confirm password',
      newPassword: 'New password',
      validation: 'Password does not match',
      passwordValidation: 'Password must contain at least 1 letter and 1 number.',
      username: 'Email'
    },
    buttons: {
      back: 'Back',
      save: 'Save',
      update: 'Update',
      delete: 'Delete',
      create: 'Create',
      send: 'Send',
      deploy: 'Deploy'
    },
    misc: {
      createdAt: 'Created',
      updatedAt: 'Updated',
      createdBy: 'By',
      updatedBy: 'By',
      infosTitle: 'Infos'
    },
    general: {
      title: 'general',
    },
    users: {
      titles: {
        list: 'Users',
        create: 'Create user',
        edit: 'Edit user',
        details: 'User details'
      },
      search: {
        name: 'Search by username'
      },
      list: {
        firstname: 'Firstname',
        lastname: 'Lastname',
        email: 'Email',
        enabled: 'Enabled',
        password: 'Password',
        language: 'Language'
      },
      sections: {
        credentials: 'Credentials',
        identity: 'Identity',
        rights: 'Rights'
      },
      roles: {
        admin: 'Administrator',
        designer: 'Designer',
        trainer: 'Trainer',
        trainee: 'Trainee',
        title: 'Roles'
      },
      languages: {
        en: 'English',
        fr: 'French',
      },
      become: {
        notEnabledMessage: 'User not enabled',
        noTokenFoundMessage: 'No token found',
        title: 'Become'
      },
      create: {
        successMessage: 'User created',
        errorMessage: 'Failed',
        title: 'Create User'
      },
      edit: {
        successMessage: 'User updated',
        errorMessage: 'Failed',
        title: 'Create User'
      },
      delete: {
        successMessage: 'User deleted',
        errorMessage: 'Error deleting User',
        title: 'Delete user',
        confirmMessage: 'Are you sure you want to delete this user?',
        button: 'delete'
      },
      myprofile: 'My profile'
    },
    workspaces: {
      name: 'Name',
      description: 'Description',
      number: 'Number',
      locator: 'Locator',
      firstname: 'Firstname',
      lastname: 'Lastname',
      trainer: 'Trainer',
      numberOfVMs: 'Number of Vms',
      email: 'Email',
      titles: {
        myworkspace: 'My Workspace',
        workspace: 'Workspace',
      },
      myworkspace: {
        noWorkspaceMessage: 'No workspace found'
      },
      title: 'Workspace'
    },
    classrooms: {
      name: 'Name',
      description: 'Description',
      number: 'Number',
      locator: 'Locator',
      firstname: 'Firstname',
      lastname: 'Lastname',
      trainer: 'Trainer',
      numberOfVMs: 'Number of Vms',
      email: 'Email',
      noClassroomMessage: "It appears that you have not been assigned to any classrooms. Please contact support for assistance.",
      noClassroomTitle: "No Classroom Found",
      create: {
        successMessage: 'Classroom created',
        errorMessage: 'Failed',
        title: 'Create classroom'
      },
      edit: {
        successMessage: 'Classroom updated',
        errorMessage: 'Failed to update classroom',
        title: 'Update classroom',
        data: 'Data',
        commonPod: 'Common Pod',
        userPod: 'User Pod',
        tags: 'Tags'
      },
      clear: {
        successMessage: 'Classroom cleared',
        errorMessage: 'Cannot clear classroom',
        title: 'Clear classroom',
        message: 'Are you sure you want to clear this classroom?',
        button: 'clear'
      },
      myclassroom: {
        errorMessage: 'No classroom found'
      },
      delete: {
        successMessage: 'Classroom deleted',
        errorMessage: 'Error deleting classroom',
        title: 'Delete classroom',
        confirmMessage: 'Are you sure you want to delete this classroom?',
        button: 'delete'
      },
      inUsed: 'In-use',
      trainees: 'Trainees',
      title: 'Classrooms',
    },
    pods: {
      common: 'Common Pod',
      user: 'User Pod',
      guestOS: 'Guest OS',
      ipAddress: 'IP Address',
      instances: 'Instances',
      networks: 'Networks',
      network: 'Network',
      routers: 'Routers',
      vms: 'Vms',
      volumes: 'Volumes',
      firewalls: 'Firewalls',
      name: 'Name',
      trainees: 'Trainees',
      status: 'Status',
      description: 'Description',
      type: 'Type',
      show: 'Show',
      size: 'Size',
      noPodMessage: 'No Pod is assigned to your account. Please contact support for assistance.',
      associate: {
        successMessage: 'User associated to pod',
        errorMessage: 'Cannot associate pod to user',
        title: 'Associate User to Pod',
        message: 'Are you sure you want to disassociate this user?',
        button: 'Associate',
        data: 'Data',
        user: 'User'
      },
      disassociate: {
        successMessage: 'User disassociated to pod',
        errorMessage: 'Cannot disassociate pod to user',
        title: 'Disassociate User to Pod',
        message: 'Are you sure you want to disassociate this user?',
        button: 'Disassociate',
        data: 'Data',
        user: 'Users'
      },
      delete: {
        successMessage: 'Pod deleted',
        errorMessage: 'Error deleting pod',
        title: 'Delete ',
        confirmMessage: 'Are you sure you want to delete this pod?',
        button: 'Delete'
      },
      start: {
        successMessage: 'VMs Starteded',
        errorMessage: 'Cannot Start one or multiple VMs',
        title: 'Start all VMs',
        message: 'Are you sure you want to start this VM?',
        button: 'Start',
        data: 'Data',
        buttonInWorkspace: 'Start All Vms',
      },
      stop: {
        title: 'Stop all VMs',
        message: 'Are you sure you want to shutdown this VM?',
        button: 'Stop',
        data: 'Data',
        buttonInWorkspace: 'Shutdown All Vms'
      },

    },
    trainingTemplates: {
      deploy: {
        successMessage: 'Classroom deployed',
        errorMessage: 'Cannot deploy classroom',
        title: 'Deploy Training Template',
        message: 'Are you sure you want to deploy this classroom?',
        button: 'Deploy',
        data: 'Data',
        buttonInWorkspace: 'Deploy Training Template'
      },
      deployUserPod: {
        successMessage: 'User Pod deployed',
        errorMessage: 'Cannot deploy user pod',
        title: 'Deploy user pods',
        message: 'How many pod users would you like to deploy?',
        button: 'Deploy User Pods',
        data: 'Data',
        podMax: 'Number of pods'
      },
      deployUserResources: {
        button: 'Deploy User Resources',
      },
      deployCommonResources: {
        successMessage: 'Common Pod resources deployed',
        errorMessage: 'Cannot deploy common resources',
        title: 'Deploy common resources',
        message: 'Data',
        button: 'Deploy Common Resources',
        data: 'Data',
      },
      clone: {
        title: 'Clone Training Template',
      },
      list: {
        name: 'Name',
        description: 'Description',
        language: 'Language',
        production: 'Production',
        step: 'Step',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdBy: 'Created by',
        updatedBy: "Updated by",
      },
      create: {
        successMessage: 'created',
        errorMessage: 'Failed',
        title: 'Create training template',
        data: 'Data',
        commonPod: 'Common Pod',
        userPod: 'User Pod',
        commonPodInstance: 'Common Pod Instances',
        userPodInstance: 'Trainee Pod Instances',
        tags: 'Tags'
      },
      edit: {
        successMessage: 'Created',
        errorMessage: 'Failed',
        title: 'Edit training template',
        data: 'Data',
        commonPod: 'Common Pod',
        userPod: 'User Pod',
        commonPodInstance: 'Common Pod Instances',
        userPodInstance: 'Trainee Pod Instances',
        tags: 'Tags'
      },
      delete: {
        successMessage: 'Training template deleted',
        errorMessage: 'Error deleting training template',
        title: 'Delete training template',
        confirmMessage: 'Are you sure you want to delete this training template?',
        button: 'Delete',
        warningProduction: 'This training template is in production. Are you sure you want to delete it?'
      },
      replace: {
        button: 'Replace',
      },
      title: 'Training Template',
      export: {
        exportFailed: 'Error exporting data',
        exportSuccessful: 'Export successful',
      },
    },
    flavors: {
      name: 'Name',
      description: 'Description',
      create: {
        successMessage: 'Flavor created',
        errorMessage: 'Failed',
        title: 'Create flavor'
      },
      ram: {
        title: 'Ram (MB)'
      },
      disk: {
        title: 'Disk (GB)'
      },
      buttons: {
        deleteConfirmButton: 'You will not be able to recover this record. Are you sure?',
        deleteButtonTitle: 'Delete flavor'
      }
    },
    images: {
      format: 'Format',
      size: 'Size',
      status: 'Status',
      name: 'Name',
      convert: 'Convert OVA to QCOW2',
      convertTitle: 'Convert OVA',
      createdBy: 'Created by',
      create: {
        title: 'Create source image',
        filename: 'File'
      },
      edit: {
        title: 'Edit Source'
      },
      delete: {
        successMessage: 'Image deleted',
        errorMessage: 'Error deleting image',
        title: 'Delete image',
        confirmMessage: 'Are you sure you want to delete this image?',
        button: 'Delete'
      },
      task: {
        successMessage: 'Image deleted',
        deleted: 'Image deleted',
        created: 'Image have been created',
        uploading: 'Upload image in progress',
        initialized: 'Initialiaze in progress ',
        done: 'Done',
        error: 'Error'
      }
    },
    instanceTemplates: {
      deploy: {
        successMessage: 'Instance template deployed',
        errorMessage: 'Error deploying Instance template',
        title: 'Deploy Instance Template',
        message: 'Are you sure you want to deploy this instance ?',
        button: 'Deploy',
        data: 'Data',
        classroom: 'Classroom',
        image: 'Image',
        boot: 'Media device',
        instanceTemplate: 'Instance Template',
        selectPodMessage: 'Please select a Pod',
        partialFailureMessage: 'Failed Pod are : ',
        podColumn: 'Pod',
        statusColumn: 'Status',
        statusDialogTitle: 'Deploy Instance Template Task status',
        closeButton: 'Close',
        errorMessageColumn: 'Error message'
      },
      create: {
        successMessage: 'Instance template created',
        errorMessage: 'Error creating Instance template',
        inProgress: 'Creating Instance template in progress',
        message: 'Create in progress',
        data: 'Data',
        imageName: 'Name',
        pod: 'Pod',
        vm: 'VM',
        title: 'Create Instance Template',
        UseExistingVolumes: 'Use existing Volumes',
        ipAddress: 'IP Address',
        macAddress: 'MAC Address',
      },
      delete: {
        successMessage: 'Instance template deleted',
        errorMessage: 'Error deleting Instance template',
        title: 'Delete instance template',
        confirmMessage: 'Are you sure you want to delete this template?',
        button: 'Delete',
        volumeSnapshotsMessage: 'Delete volumes',
        inProgressMessage: 'Delete in progress'
      },
      bulkDelete: {
        successMessage: 'Instances templates deleted',
        errorMessage: 'Error deleting Instances templates',
        title: 'Delete instances templates',
        confirmMessage: 'Are you sure you want to delete these templates?',
        button: 'Delete'
      },
      edit: {
        title: 'Edit instance Template',
        successMessage: 'Instance template updated',
        errorMessage: 'Error updating Instance template',
      },
      list: {
        title: 'Instance templates',
        status: 'Status',
        volumeSnapshots: 'Volume Snapshots',
        versioning: 'Version',
        name: 'Name',
        language: 'Language',
        production: 'Production',
        createdBy: 'Created by',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        updatedBy: 'Updated by',
        macAddress: 'MAC Address',
        addressIp: 'IP Address',
        networkInterfaces: 'Interfaces',
        templateType: 'Template type',
        migration: 'Migration'
      },
      replace: {
        replace: 'Replace or delete instance template in one or mutliple training templates',
        title: 'Replace or delete instance template',
        title1: 'Replace or delete '
      },
      addToTrainingTemplate: {
        button: 'Add To TrainingTemplate',
        successMessage: 'Instance template added to Training template',
        errorMessage: 'Error adding Instance template',
        title: 'Add Instance Template to training template',
        message: 'Are you sure you want to add instance template to training template ?',
        data: 'Data',
        classroom: 'Classroom',
        trainingTemplate: 'Training Template',
        image: 'Image',
        boot: 'Boot'
      },
      addInstanceTemplate: {
        button: 'Add instance template',
      },
      export: {
        exportFailed: 'Error exporting data',
        exportSuccessful: 'Export successful',
      },
      deleteMany: {
        button: 'Delete',
        confirmTitle: 'Confirm Deletion',
        warningMessage: 'Are you sure you want to delete these instance templates? This action cannot be undone.',
        cancel: 'Cancel',
        confirm: 'Confirm Delete',
        inProgress: 'Deleting instance templates...',
        resultTitle: 'Results',
        close: 'Close',
        name: 'Name',
        status: 'Status',
        error: 'Error',
        message: 'Message',
        success: 'Success',
        currentlyDeleting: 'Deleting'
      },
      title: 'Instance Template'
    },
    networks: {
      ports: {
        enable: 'Enable',
        disable: 'Disable',
        connect: 'Connect',
        disconnect: 'Disconnect',
        successMessage: 'Port Updated',
        errorMessage: 'Error updating port',
        state: 'Status',
        action: 'Action',
        allowAddressPairs: 'Add Address Pairs',
        removeAddressPairs: 'Remove Address Pairs',
        addressPairs: 'Address pairs',
        allowSuccessMessage: 'Add address Pairs',
        allowErrorMessage: 'Error adding address pairs',
        removeSuccessMessage: 'Remove address Pairs',
        removeErrorMessage: 'Error removing address pairs',
        updateDnsName: "Update DNS Name",
        dnsNameUpdated: "DNS Name updated successfully",
        dnsNameUpdateError: "Error updating DNS Name",
        dnsName: "DNS Name",
        cancel: "Cancel",
        submit: "Submit",
        fqdn: 'FQDN'
      },
      status: 'Status',
      name: 'Name',
      surname: 'Surname',
      cidr: 'Cidr',
      description: 'Description',
      macAddress: 'MAC Address'
    },
    routers: {
      status: 'Status',
      name: 'Name',
      surname: 'Surname',
      cidr: 'Cidr',
      description: 'Description',
    },
    securityGroups: {
      create: {
        successMessage: 'Security group created',
        errorMessage: 'Error creating Security group ',
        data: 'Data',
        pod: 'Pod',
        title: 'Create Security group ',
        button: 'Create',
      },
    },
    securityGroupRules: {
      name: 'Security Group Rules',
      protocol: 'IP Protocol',
      delete: {
        successMessage: 'Security group rule deleted',
        errorMessage: 'Error deleting security group rule',
        title: 'Delete',
        confirmMessage: 'Are you sure you want to delete this rule?',
        button: 'Delete'
      },
      create: {
        successMessage: 'Security group rule created',
        errorMessage: 'Error creating Security group rule',
        data: 'Data',
        pod: 'Pod',
        title: 'Create Security group rule',
        button: 'Add rule',
      },
    },
    vms: {
      status: 'Status',
      name: 'Name',
      description: 'Description',
      guestOS: 'Guest OS',
      hideError: 'Hide error',
      showError: 'Show error',
      mountedOn: 'Mounted on',
      networks: 'Networks',
      sizeGo: 'Size (Go)',
      language: 'Language',
      task: 'Task',
      create: {
        successMessage: 'Instance created',
        errorMessage: 'Error creating Instance',
        data: 'Data',
        imageName: 'Name',
        pod: 'pod',
        title: 'Create Instance',
        button: 'create',
        disk: 'Disk',
        resource: 'Resource(s)',
        mediadevice: 'Media device',
        flavor: 'Flavor',
        network: 'Network',
        boot: 'Boot',
        bootFromVolume: 'Boot from Volume',
        image: 'Image',
        template: 'Template',
        ipAddress: 'IP Address',
        macAddress: 'MAC Address',
        emptyVolumes: 'Create empty volume (GB)'
      },
      show: {
        status: 'Status',
        ipAddress: 'ipAddress',
        error: 'Error',
        visibility: 'visibility',
        title: 'Instance',
        attachedTo: 'Attached To',
        mediaDevice: 'Media device',
        interface: 'Interfaces',
        instanceTemplate: 'Instance Template'
      },
      delete: {
        successMessage: 'Instance deleted',
        errorMessage: 'Error deleting Instance ',
        title: 'Delete',
        confirmMessage: 'Are you sure you want to delete this Instance?',
        button: 'Delete'
      },
      edit: {
        successMessage: 'Instance updated',
        errorMessage: 'Error updating Instance ',
        title: 'Edit Instance',
        button: 'Edit'
      },
      action: {
        start: 'Start',
        stop: 'Stop',
        suspend: 'Suspend',
        resume: 'Resume',
        reboot: 'Reboot',
        resize: 'Resize',
        createInterface: 'Create interface',
        removeInterface: 'Remove interface',
        guacamole: 'Guacamole',
        vncConsole: 'VNC',
        attachVolume: 'Attach volume',
        detachVolume: 'Remove volume',
        createInstanceTemplate: 'create Template',
        addSecurityGroup: 'Add security group',
        removeSecurityGroup: 'Remove security group'
      },
      control: {
        addSecurityGroup: {
          data: 'Data',
          title: 'Add security group'
        },
        removeSecurityGroup: {
          data: 'Data',
          title: 'Remove security group'
        },
        consoleVNC: {
          title: 'VNC Console',
          errorMessage: 'Cannot get console',
          successMessage: 'Console received'
        },
        consoleGuac: {
          title: 'Guacamole Console',
          errorMessage: 'Cannot get guacamole console',
          successMessage: 'Console guacamole received',
          guacMessage: 'Guacamole Connection ',
          VMAuthentication: 'VM Authentication',
          UserAuthentication: 'User Authentication',
        },
        removeInterface: {
          title: 'Remove VM Interface',
          data: 'Data',
          errorMessage: 'Cannot remove interface of the instance',
          successMessage: 'Interface removed',
          port: 'Port'
        },
        createInterface: {
          title: 'Create VM interface',
          errorMessage: 'Cannot create interface',
          successMessage: 'Interface created',
          data: 'Data',
          network: 'Network'
        },
        resize: {
          title: 'Resize instance',
          data: 'Data',
          flavor: 'Flavor',
          errorMessage: 'Cannot resized instance',
          successMessage: 'Instance resized',
          port: 'Port'
        },
        reboot: {
          title: 'Reboot instance',
          button: 'Reboot',
          data: 'Data',
          flavor: 'Flavor',
          errorMessage: 'Cannot reboot instance',
          successMessage: 'Instance restarted',
          port: 'Port'
        },
        startAll: {
          title: 'Start all instances',
          button: 'Start All',
          data: 'Data',
          flavor: 'Flavor',
          errorMessage: 'Cannot start one or multiple instances',
          successMessage: 'All instances are started',
          port: 'Port',
          confirmMessage: 'Are you sure you want to start All Vms?'
        },
        stopAll: {
          title: 'Stop all instances',
          button: 'Stop All',
          data: 'Data',
          flavor: 'Flavor',
          errorMessage: 'Cannot stop one or multiple instances',
          successMessage: 'All instances are shutdown',
          port: 'Port',
          confirmMessage: 'Are you sure you want to shutdown All Vms?'
        },
        guac: {
          title: 'Connect to guacamole console',
          data: 'Data'
        },
        volume: {
          titleAttach: 'Attach volume',
          titleDetach: 'Detach volume',
          data: 'Data',
          list: 'Volumes',
        }
      }
    },
    firewalls: {
      status: 'Status',
      name: 'Name',
      description: 'Description',
      guestOS: 'Guest OS',
      hideError: 'Hide error',
      showError: 'Show error',
      mountedOn: 'Mounted on',
      networks: 'Networks',
      sizeGo: 'Size (Go)',
      language: 'Language',
      task: 'Task',
    },
    trainings: {
      title: 'Trainings',
      name: 'Name',
      description: 'Description',
      number: 'Number',
      trainingTemplate: 'Training Template',
      room: 'Room',
      locator: 'Locator',
      trainer: 'Trainer',
      podMax: 'Pod max',
      trainingTitle: 'Title',
      edit: {
        title: 'Edit training'
      },
      buttons: {
        deleteConfirmButton: 'You will not be able to recover this record. Are you sure?',
        deleteButtonTitle: 'Delete training'
      },
      associate: {
        successMessage: 'Trainer associated to training',
        errorMessage: 'Cannot associate training to trainer',
        title: 'Associate trainer to training',
        message: 'Are you sure you want to disassociate this user?',
        button: 'Associate',
        data: 'Data',
      },
      disassociate: {
        successMessage: 'Trainer disassociated to training',
        errorMessage: 'Cannot disassociate training to trainer',
        title: 'Disassociate trainer to training',
        message: 'Are you sure you want to disassociate this user?',
        button: 'Disassociate',
        data: 'Data',
      },
      ports: {
        successMessage: 'The port has been successfully updated',
      },
      delete: {
        successMessage: 'Training deleted',
        errorMessage: 'Error deleting training',
        title: 'Delete',
        confirmMessage: 'Are you sure you want to delete this training?',
        button: 'delete'
      },
    },
    volumes: {
      title: 'Volumes',
      mountedOn: 'Mounted on',
      networks: 'Networks',
      sizeGo: 'Size (Go)',
      status: 'Status',
      name: 'Name',
      description: 'Description',
      volumeName: 'Volume Name',
      createdAt: 'Created at ',
      updatedAt: 'Updated at ',
      bootable: 'Bootable',
      overview: 'Volume Overview',
      list: {
        status: 'Status',
        name: 'Name',
        description: 'Description',
        number: 'Number',
        size: 'Size',
        device: 'Device',
        bootable: 'Bootable',
        volumeSnapshots: 'VolumeSnapshots'
      },
      edit: {
        title: 'Edit volume'
      },
      create: {
        button: 'create',
        title: 'Create Volume',
        size: 'Size (GB)'
      },
      buttons: {
        deleteConfirmButton: 'You will not be able to recover this record. Are you sure?',
        deleteButtonTitle: 'Delete volume'
      },
      delete: {
        successMessage: 'Volume deleted',
        errorMessage: 'Error deleting volume',
        title: 'Delete',
        confirmMessage: 'Are you sure you want to delete this volume?',
        button: 'Delete'
      },
    },
    files: {
      size: 'Size',
      name: 'Name',
      title: 'File',
      type: 'Type',
      select: 'Select a file',
      description: 'Description',
      number: 'Number',
      create: 'Create New Folder',
      upload: 'Upload a file',
      createFolder: 'Create Folder',
      createButton: 'Create',
      cancelButton: 'Cancel',
      deleteConfirmMessage: 'Are you sure you want to delete this file?',
      cancel: 'Cancel',
      confirm: 'Confirm',
      delete: {
        confirmMessage: 'Are you sure you want to delete this file?',
        title: 'Delete',
        button: 'Delete'
      }
    },
    hypervisors: {
      name: 'Name',
      numberOfVMs: 'Number of VMs',
      totalCPU: 'Total CPU',
      vcpuAllocationRatio: 'vCPU Allocation Ratio',
      usedvCPU: 'Used vCPU',
      freevCPU: 'Free vCPU',
      usedDisk: 'Used Disk (GB)',
      freeDisk: 'Free Disk (GB)',
      totalDisk: 'Total Disk (GB)',
      summary: 'Resource summary',
      globalUsage: 'Global Usage',
      state: 'State',
      freeRAM: 'Free RAM (GB)',
      usedRAM: 'Used RAM (GB)'
    },
    tasks: {
      name: 'Name',
      description: 'Description',
      number: 'Number',
      overallProgress: 'Overall Progress',
      status: 'Status',
      type: 'Type',
      createdAt: 'Created At'
    },
    dashboard: {
      welcomeTitle: 'Welcome to Remote Labs',
      title: 'Dashboard'
    },
    help: {
      diskBusHelp: 'Disk bus Type help',
    },
    emptyMessage: 'No data found',
    LoadingMessage: 'Loading...',
    ErrorMessage: 'Error!',
    notAllowed: 'Not Allowed',
    notAllowedMessage: 'You do not have the necessary permissions to access this page. Please contact your administrator if you believe this is an error.'
  }
};

export default englishCustomMessages;