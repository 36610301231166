const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
        case 'success':
            return 'success.main'; // MUI theme color
        case 'failed':
            return 'error.main';
        case 'ongoing':
            return 'warning.main';
        default:
            return 'text.primary';
    }
};

export default getStatusColor;