
import decodeJwt from 'jwt-decode';
import gravatar from 'gravatar';
import axios from 'axios';

const redirectToLogin = () => {
  if (!window.location.pathname.includes("/login")) {
      console.warn("Redirecting to login...");
      if (window.location.hash.includes("#")) {
          window.location.replace("/#/login"); // Fixes hash-based routing
      } else {
          window.location.replace("/login"); // Normal routing
      }
  }
};

const AuthProvider = {
  // authentication
  login: async ({ username, password }) => {
    try {
      const data = { username, password };
      const options = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const res = await axios.post('/api/v1/auth/login', data, options);
      if (res.data && res.data.token) {
        localStorage.setItem('token', res.data.token);
      }
      else throw new Error('No token found');
    }
    catch (error) {
      if (error.response && error.response.data && error.response.data.message) throw new Error(error.response.data.message);
      else throw new Error(error);
    }
  },
  checkError: (error) => {
    console.log(error, 'error');
    const status = error ? error.status ? error.status : null : null;  // Fix for Axios response

    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject({ redirectTo: "/login" }); // Ensure redirect to login
    }

    return Promise.resolve(); // Other errors do not require redirection
  },

  checkAuth: async () => {
    const token = localStorage.getItem('token');
    // if (!token) return Promise.reject({ redirectTo: "/login" }); // Force redirect
    if (!token) {
      console.warn("checkAuth : No token found, redirecting...");
      localStorage.removeItem("token");
      return Promise.reject({ redirectTo: "/login" });
    }

    try {
      const { exp } = decodeJwt(token);
      if (exp * 1000 < Date.now()) {
        console.warn("Token expired, redirecting...");
        localStorage.removeItem("token");
        return Promise.reject({ redirectTo: "/login" });
      }
      return Promise.resolve();
    } catch (error) {
      console.error("Invalid token, redirecting...");
      localStorage.removeItem("token");
      return Promise.reject({ redirectTo: "/login" });
    }
  },
  logout: async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('guacamoleToken');
  },
  getIdentity: () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.warn("GetIdentity : No token found, redirecting to login...");
        localStorage.removeItem('token');
        redirectToLogin();
        return Promise.reject();
      }

      let decoded;
      try {
        decoded = decodeJwt(token); // Catch decoding errors
      } catch (error) {
        console.error("Invalid token specified, redirecting...");
        localStorage.removeItem("token");
        redirectToLogin();
        return Promise.reject({ redirectTo: "/login" }); // Redirect if token is invalid
      }

      const { sub, exp } = decoded;

      if (exp * 1000 < Date.now()) {
        console.warn("Token expired, redirecting to login...");
        localStorage.removeItem('token');
        redirectToLogin();
        return Promise.reject();
      }

      return Promise.resolve({
        id: sub.id,
        fullName: sub.fullName,
        avatar: gravatar.url(sub.email, { protocol: 'https', s: '40', r: 'g', d: 'identicon' }, false),
      });
    } catch (error) {
      console.error("GetIdentity : Invalid token, redirecting to login...");
      localStorage.removeItem('token');
      redirectToLogin();
      return Promise.reject();
    }
  },
  getPermissions: () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.warn("No token found, redirecting...");
        localStorage.removeItem('token');
        return Promise.reject();
      }

      const { sub, exp } = decodeJwt(token);

      if (exp * 1000 < Date.now()) {
        console.warn("Token expired, redirecting...");
        localStorage.removeItem('token');
        return Promise.reject();
      }

      return sub.roles ? Promise.resolve(sub.roles) : Promise.reject();
    } catch (error) {
      console.error("GetPermissions : Invalid token, redirecting...");
      localStorage.removeItem('token');
      return Promise.reject();
    }
  },
}

export default AuthProvider;