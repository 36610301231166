import { Fragment, useState } from 'react';
import {
    SimpleForm, useTranslate,
    Toolbar, SaveButton, useCreate,
    useNotify, useRedirect, useRefresh, NumberInput,
    Button,
    useGetIdentity,
} from 'react-admin';

import { SectionField } from '../../components';
import { makeStyles } from '@mui/styles';
import { Description, Cancel, Send } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import io from 'socket.io-client';
import StatusProgress from '../../components/deploymentProgress';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 40
    },
    deployButton: {
        display: 'flex !important',
        justifyContent: 'right !important',
    },
    color: {
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        border: theme.palette.primary.main
    },
}));

const Validate = (values) => {
    const errors = {};
    if (!values.pod_max) errors.pod_max = 'ra.validation.required';
    return errors;
};


const DeployNewPodUser = (props) => {
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const [socket, setSocket] = useState(null); // State to hold the socket reference
    const { data: identity, isLoading, error } = useGetIdentity();
    const [deployments, setDeployments] = useState({}); // Store deployment state for each tab
    const [showProgressDialog, setShowProgressDialog] = useState(false); // New dialog state
    const [progressData, setProgressData] = useState({ progress: 0, statusText: '' });
    const [socketRef, setSocketRef] = useState(null); // State to hold the socket reference

    if (isLoading) return <div>{translate('app.LoadingMessage')}</div>;

    const { id: userId } = identity;

    const BackendURL = process.env.REACT_APP_PROGRESS_URL; // Replace with your backend URL

    const CreateToolbar = () => {

        return (
            <Toolbar className={classes.toolbar}>
                <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.color}>
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.deploy')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const closeSocket = () => {
        // Disconnect the socket when the dialog is closed
        try {
            console.log('WebSocket connection should be disconnected.');

            if (socketRef && socketRef.connected) {
                socketRef.disconnect();
                console.log('WebSocket connection disconnected.');
            }

            setShowProgressDialog(false);
        } catch (error) {
            console.error('Error disconnecting WebSocket:', error);
        }
    };

    const trainingTemplateDeploy = (data) => {
        handleShowProgressDialog();

        const dataToSend = {
            "room": props.room,
            "trainingTemplate": props.trainingTemplate,
            "pod_max": data.pod_max
        };

        create(`trainingTemplates/deployNewPodUser`, { data: dataToSend }, {
            onSuccess: response => {

                if (!response) {
                    notify('ra.notification.created', 'info');

                } else {
                    //notify(translate('app.trainingTemplates.deployUserPod.successMessage'), { type: 'success' });
                    //redirect('list', 'classrooms');
                    //refresh();
                }
            },
            onError: error => {
                if (error.message) {
                    notify(`${translate('app.trainingTemplates.deployUserPod.errorMessage')}: ${error.message}`, { type: 'error' });
                }
                else {
                    notify(translate('app.trainingTemplates.deployUserPod.errorMessage'), { type: 'error' });
                }
            }
        });
        handleCloseClick();
    };

    const handleShowProgressDialog = async () => {
        if (!userId) {
            // If userId is not available, return early
            return;
        }

        const socket = io(BackendURL, {
            query: { userId }, // Pass the userId as a query parameter
        });

        socket.on('connect', () => {
            console.log('WebSocket connection established.');
        });

        socket.on('disconnect', () => {
            console.log('WebSocket connection closed.');
        });

        socket.emit('joinRoom', userId);

        // Event handler for 'deploymentProgress' event from the backend
        socket.on('deploymentProgress', ({ userId, progress, statusText, trainingId, status }) => {

            // Update the progress and statusText in the component state
            setProgressData({ progress, statusText });

            // Open the progress dialog when the event is received
            setShowProgressDialog(true);


            const updatedDeployments = { ...deployments };
            updatedDeployments[userId] = { progress, statusText, status };
            setDeployments(updatedDeployments);
        });
        setSocketRef(socket); // Store the socket reference in state

    };

    return (
        <Fragment>
            <Button label={translate('app.trainingTemplates.deployUserPod.button')} className={classes.deployButton}
                onClick={handleClick}><Send /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.trainingTemplates.deployUserPod.title')}
            >
                <DialogTitle><SectionField label={translate('app.trainingTemplates.deployUserPod.title')} icon={Send} /></DialogTitle>
                <DialogContent>
                    <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={trainingTemplateDeploy} redirect="list">
                        <SectionField label={translate('app.trainingTemplates.deployUserPod.message')} icon={Description} />
                        <NumberInput source="pod_max" min='0' label={translate('app.trainingTemplates.deployUserPod.podMax')} />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            {/* Render your progress bar or other UI elements */}
            {/* New Dialog to show progress and status */}
            {Object.keys(deployments).map((userId) => (
                <Dialog
                    key={userId}
                    fullWidth
                    open={true} // Show dialog based on deployment state
                    onClose={() => {
                        const updatedDeployments = { ...deployments };
                        delete updatedDeployments[userId];
                        setDeployments(updatedDeployments);
                        closeSocket();
                    }}
                    aria-label="Deployment Progress"
                >
                    <DialogTitle>
                        <SectionField label='Deployment Progress' icon={Send} />
                    </DialogTitle>
                    <DialogContent>
                        <StatusProgress deployments={deployments} userId={userId} />
                    </DialogContent>
                </Dialog>
            ))}
        </Fragment>
    );
};

export default DeployNewPodUser;
