const { Typography, Box } = require("@mui/material");
const { default: getStatusColor } = require("./getStatusColor");


const StatusProgress = ({ deployments, userId }) => {
    const userDeployment = deployments[userId];
    if (!userDeployment) return null;
    
    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="h5" gutterBottom>
                <Box component="span" sx={{ fontWeight: 700 }}>Progress:</Box> {userDeployment.progress}%
            </Typography>
            <Typography variant="h5" gutterBottom>
                <Box component="span" sx={{ fontWeight: 700 }}>Status:</Box>{' '}
                <Box
                    component="span"
                    sx={{
                        fontWeight: 700,
                        color: getStatusColor(userDeployment.status),
                        display: 'inline', // ensure inline rendering
                    }}
                >
                    {userDeployment.status}
                </Box>
            </Typography>

            <Typography variant="h5" gutterBottom>
                <Box component="span" sx={{ fontWeight: 700 }}>Step:</Box> {userDeployment.statusText}
            </Typography>
        </Box>
    )
};

export default StatusProgress;