import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import { createTheme } from '@material-ui/core/styles'

const CustomTheme = createTheme(merge({}, defaultTheme, {
  palette: {
    type: 'light',
    primary: {
      main: '#6b489d',
    }
  },
  sidebar: {
    closedWidth: 50,
    width: 200
  },
  typography: {
    fontSize: 12
  },
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          "& .RaDatagrid-headerCell": {
            backgroundColor: "#ebecf3",
            fontWeight: 'bold',
          },
        }
      }
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ebecf3",
          "& .RaSidebar-fixed": {
            backgroundColor: "#ebecf3",
            scrollbarWidth: 'thin'
          },
        }
      }

    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          "& .RaBulkActionsToolbar-toolbar": {
            position: 'inherit'
          },
        }
      }
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          ".RaLabeled-label": {
            fontSize: "0.90em",
            fontWeight: 800
          }
        },
      }
    },
    RaLayout: {
      styleOverrides: {
        root: {
          ".RaLayout-root": {
            overflow: "hidden !important", // Prevent double scrollbar on parent
            height: "100vh", // Ensure full viewport height
            display: "flex",
            flexDirection: "column",
          },
          ".RaLayout-content": {
            marginLeft: "5px",
            overflowY: "auto !important",
            height: "calc(100vh - 64px)",
            flexGrow: 1,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
          },
        },
      },
    }
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#fff',
        backgroundColor: '#6b489d',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 'unset'
      }
    },
    RaDatagrid: {
      headerCell: {
        fontWeight: 'bold'
      }
    },
    RaUserMenu: {
      avatar: {
        border: '1px solid white',
        width: 20,
        height: 20
      }
    },
    RaLoadingIndicator: {
      loadedIcon: {
        display: 'none'
      },
      loader: {
        display: 'none'
      }
    },
    RaSidebar: {
      fixed: {
        position: 'inherit'
      }
    }
  },
})
);

export default CustomTheme;