import { useState, useEffect } from 'react';
import {
  useDataProvider, Error, Loading, useTranslate,
  useRedirect
} from 'react-admin';
import WorkspaceShow from './workspaceShow';
import decodeJwt from 'jwt-decode';

const MyWorkspace = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [workspaceId, setWorkspaceId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.warn("MyWorkspace: No token found, redirecting...");
      localStorage.removeItem("token");
      redirect("/login");
      return;
    }

    let decoded;
    try {
      decoded = decodeJwt(token);
    } catch (err) {
      console.error("MyWorkspace: Invalid token, redirecting...");
      localStorage.removeItem("token");
      redirect("/login");
      return;
    }

    const { sub, exp } = decoded;

    if (exp * 1000 < Date.now()) {
      console.warn("MyWorkspace: Token expired, redirecting...");
      localStorage.removeItem("token");
      redirect("/login");
      return;
    }

    dataProvider
      .getOne("users", { id: sub.id })
      .then(({ data }) => {
        if (data && data.workspace) {
          setWorkspaceId(data.workspace);
          setLoading(false);
        } else {
          setError(translate("app.workspaces.myworkspace.noWorkspaceMessage"));
          setLoading(false);
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, redirect, translate]);

  if (loading) return <Loading />;
  if (error) return <Error error={error.message ? error.message : error} />;
  if (!workspaceId) return null;


  return (
    <div>
      <WorkspaceShow id={workspaceId} resource="workspaces" myWorkspace="true" />
    </div>
  )

};

export default MyWorkspace;
