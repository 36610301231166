import { useState, useEffect } from 'react';
import {
  useDataProvider, Error, Loading,
  useTranslate,
  useRedirect
} from 'react-admin';
import PodShow from './podShow';
import decodeJwt from 'jwt-decode';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NoPodMessage = () => {
  const translate = useTranslate();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
      <Card sx={{ maxWidth: 500, padding: 3, textAlign: 'center' }}>
        <CardContent>
          <ErrorOutlineIcon color="error" style={{ fontSize: 50, marginBottom: 10 }} />
          <Typography variant="h6" color="textSecondary" gutterBottom>No Pod found</Typography>
          <Typography variant="body1">
            {translate('app.pods.noPodMessage')}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const MyPod = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [podId, setPodId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.warn("MyPod: No token found, redirecting to login...");
      localStorage.removeItem("token");
      redirect("/login");
      return;
    }

    let decoded;
    try {
      decoded = decodeJwt(token);
    } catch (err) {
      console.error("MyPod: Invalid token, redirecting to login...");
      localStorage.removeItem("token");
      redirect("/login");
      return;
    }

    const { sub, exp } = decoded;

    if (exp * 1000 < Date.now()) {
      console.warn("MyPod: Token expired, redirecting to login...");
      localStorage.removeItem("token");
      redirect("/login");
      return;
    }

    dataProvider
      .getOne("users", { id: sub.id })
      .then(({ data }) => {
        if (data && data.pod) {
          setPodId(data.pod);
        } else {
          setPodId(null); // No pod assigned
        }
      })
      .catch((error) => {
        setError(error); // Set error for actual data fetching issues
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, redirect]); // No early returns before hooks

  if (loading) return <Loading />;

  if (error) return <Typography color="error">{error.message || 'An unexpected error occurred'}</Typography>;

  // Display NoPodMessage if no pod is assigned to the user
  if (!podId) return <NoPodMessage />;

  // Display PodShow when podId is available
  return <PodShow id={podId} resource="pods" />;
};

export default MyPod;